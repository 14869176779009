import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import appContext from '../../context/appContext';
import { fetchData } from '../../data/fetchData';
import CartProducts from './CartProducts';
import Logistics from '../../UI/Logistics';
import Payment from '../../UI/Payment';
import style from './Cart.module.css';
import DateTimePicker from '../../UI/DateTimePicker';
import { NavLink, useNavigate } from 'react-router-dom';

const getInitialFormData = ({ address, deliveryOption, location, comment }) => ({
    address,
    deliveryOption,
    location,
    deliveryDate: getDeliveryDate(),
    deliveryTiming: getDeliveryTiming(),
    timeInterval: '',
    payment: 'liqpay',
    paid: false,
    comment,
    noCallback: true,
    birthday: false,
    finalSum: 0,
    discount: 0,
});

const getDeliveryDate = () => {
    let today = new Date();
    today.setDate(today.getDate() + 1);
    const isNewYear = today.toDateString() === new Date(2024, 11, 31).toDateString() || today.toDateString() === new Date(2025, 0, 1).toDateString();
    if (isNewYear) today = new Date(2025, 0, 2);

    const hours = today.getHours();
    if (hours >= 21) {
        today.setDate(today.getDate() + 1);
    }
    return today.toLocaleDateString('uk-UA', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('.').reverse().join('-');
};

const getDeliveryTiming = () => {
    const today = new Date();
    const isNewYear = today.toDateString() === new Date(2024, 11, 31).toDateString() || today.toDateString() === new Date(2025, 0, 1).toDateString();
    if (isNewYear) return 'timed';
    const hours = today.getHours();
    if (hours >= 21 || hours < 10) {
        return 'timed';
    }
    return 'nearest';
};

function Cart({ newOrderUrl, getZoneUrl, initData }) {
    const { cart, setCart, locations, related, customer, repeatOrderParams, setRepeatOrderParams } = useContext(appContext);
    const [totalSum, setSum] = useState(0);
    const [finalSum, setFinalSum] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [amount, setAmount] = useState(0);
    const [accepted, setAccepted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [clientSelected, setClientSelected] = useState(false);
    const [isOrderPossible, setIsOrderPossible] = useState(false);
    const [errorDescription, setErrorDescription] = useState('');

    const navigate = useNavigate();

    const initParams = repeatOrderParams
        ? {
              deliveryOption: repeatOrderParams.deliveryOption,
              location: repeatOrderParams.location,
              address: repeatOrderParams.address,
              comment: repeatOrderParams.comment,
          }
        : {
              deliveryOption: customer.deliveryOption,
              location: customer.location || locations[0]?.id || '',
              address: customer.address,
              comment: '',
          };

    if (repeatOrderParams) setRepeatOrderParams(null);

    const [formData, setFormData] = useState(getInitialFormData(initParams));

    const addRelatedProducts = useCallback(
        (products) => {
            products.forEach((relatedProduct) => {
                const existsInCart = cart.some((item) => item.id === relatedProduct.id);
                if (!existsInCart) {
                    setCart((prevCart) => [
                        ...prevCart,
                        {
                            id: relatedProduct.id,
                            price: relatedProduct.price,
                            amount: 0,
                            sum: 0,
                            imgUrl: relatedProduct.imgUrl,
                            title: relatedProduct.title,
                            related: true,
                            discountForbidden: true,
                        },
                    ]);
                }
            });
        },
        [cart, setCart]
    );

    useEffect(() => {
        if (!!customer && customer.identified) setClientSelected(true);
    }, [customer]);

    useEffect(() => {
        if (cart.length > 0) {
            addRelatedProducts(related.chopsticks);
            addRelatedProducts(related.sauces);
        }

        const updateComment = (currentComment, amount, label) => {
            if (amount === 0) {
                return currentComment.includes(label) ? currentComment : currentComment ? `${currentComment} ${label}` : label;
            } else {
                return currentComment.replace(label, '').trim();
            }
        };

        const chopsticksAmount = cart.reduce(
            (amount, item) => (related.chopsticks.some((chopstick) => chopstick.id === item.id) ? amount + item.amount : amount),
            0
        );
        const sauceAmount = cart.reduce((amount, item) => (item.id === '000000002' ? amount + item.amount : amount), 0);
        const gingerAmount = cart.reduce((amount, item) => (item.id === '000000006' ? amount + item.amount : amount), 0);
        const wasabiAmount = cart.reduce((amount, item) => (item.id === '000000007' ? amount + item.amount : amount), 0);

        setFormData((prev) => {
            let newComment = prev.comment || '';
            newComment = updateComment(newComment, chopsticksAmount, 'без паличок');
            newComment = updateComment(newComment, sauceAmount, 'без соєвого соуса');
            newComment = updateComment(newComment, gingerAmount, 'без імбиру');
            newComment = updateComment(newComment, wasabiAmount, 'без васабі');

            if (prev.comment !== newComment) {
                return { ...prev, comment: newComment };
            }
            return prev;
        });
    }, [cart, related.chopsticks, related.sauces, addRelatedProducts]);

    const { newSum, newAmount, customerDiscount, newFinalSum } = useMemo(() => {
        let customerDiscount = 0;

        if (formData.deliveryOption === 'pickup') {
            customerDiscount = customer.customerStatus === 'regular' ? 20 : 15;
        } else {
            if (formData.birthday) {
                customerDiscount = customer.customerStatus === 'regular' ? 15 : 10;
            } else if (formData.deliveryTiming !== 'nearest') {
                const forbiddenDate = [new Date('2025-02-14'), new Date('2025-03-08'), new Date('2025-12-31')];
                const currentDate = new Date();
                const deliveryDate = new Date(formData.deliveryDate);
                const isForbiddenDate = forbiddenDate.some((date) => deliveryDate.getTime() === date.getTime());
                if (!isForbiddenDate) {
                    const fiveHoursLater = new Date(currentDate.getTime() + 5 * 3600 * 1000);
                    const [hours, minutes] = formData.timeInterval.split('-')[0].split(':').map(Number);
                    deliveryDate.setHours(hours, minutes, 0, 0);
                    if (deliveryDate > fiveHoursLater) {
                        customerDiscount = customer.customerStatus === 'regular' ? 10 : 7;
                    }
                }
            }
        }

        const newSum = cart.reduce((sum, item) => (item.related ? sum : sum + item.sum), 0);
        const newAmount = cart.reduce((amount, item) => amount + item.amount, 0);

        const newFinalSum = cart.reduce((sum, item) => {
            if (item.related) return sum;
            if (customerDiscount === 0 || item.discountForbidden) return sum + item.sum;
            if (Math.trunc(item.discount) >= Math.trunc(customerDiscount)) {
                return sum + item.sum;
            } else {
                const itemDiscount = item.discount !== 0 ? Math.max(customerDiscount, item.discount) : customerDiscount;
                return sum + Math.trunc(item.noDiscountPrice * item.amount * (1 - itemDiscount / 100));
            }
        }, 0);

        return { newSum, newAmount, customerDiscount, newFinalSum };
    }, [cart, customer, formData.deliveryOption, formData.birthday, formData.deliveryTiming, formData.deliveryDate, formData.timeInterval]);

    useEffect(() => {
        setDiscount(customerDiscount);
        setSum(newSum.toFixed(2));
        setFinalSum(newFinalSum.toFixed(2));
        setAmount(newAmount);
    }, [newSum, newAmount, customerDiscount, newFinalSum]);

    useEffect(() => {
        const isNonCookingDay = cart.some((item) => {
            const deliveryDate = new Date(formData.deliveryDate);
            deliveryDate.setHours(0, 0, 0, 0);
            if (Array.isArray(item.nonCookingDays) && item.nonCookingDays.length > 0) {
                const isDayNotAvailable = item.nonCookingDays.some((date) => {
                    const unavailableDate = new Date(date);
                    unavailableDate.setHours(0, 0, 0, 0);
                    if (unavailableDate.getTime() === deliveryDate.getTime()) {
                        setErrorDescription(
                            `На жаль, "${item.title}" не готується ${unavailableDate.toLocaleDateString()}. Оформлення замовлення неможливе.`
                        );
                        return true;
                    }
                    return false;
                });

                return isDayNotAvailable;
            }
            return false;
        });

        setIsOrderPossible(!isNonCookingDay);

        if (!isNonCookingDay) {
            setErrorDescription('');
        }
    }, [cart, formData.deliveryDate]);

    const handlerSum = (sum) => setSum(sum);

    const handleFormChange = ({ target: { name, value, type, checked } }) => {
        if (name.startsWith('address.')) {
            const addressKey = name.split('.')[1];
            setFormData((prev) => ({
                ...prev,
                address: { ...prev.address, [addressKey]: value },
            }));
        } else if (type === 'checkbox') {
            setFormData((prev) => ({ ...prev, [name]: checked }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
        if (name === 'deliveryOption' && value === 'pickup') setFormData((prev) => ({ ...prev, birthday: false }));
        setError('');
    };

    const validateForm = () => {
        let errorMessage = '';
        if (formData.deliveryOption === 'delivery') {
            if (!formData.address.city) {
                errorMessage += 'Місто не може бути порожнім.\n';
            }
            if (!formData.address.street) {
                errorMessage += 'Вулиця не може бути порожньою.\n';
            }
            if (!formData.address.house) {
                errorMessage += 'Номер будинку не може бути порожнім.\n';
            }
        }
        if (formData.deliveryTiming === 'timed') {
            if (!formData.timeInterval) {
                errorMessage += 'Не обрано час доставки.\n';
            }
        }

        return errorMessage;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        } else {
            setError('');
        }
        const isPaying = formData.payment === 'liqpay';
        //const products = cart.map(({ products, ...rest }) => ({ ...rest, products: products.map(({ imgURL, ...nestedRest }) => nestedRest) }));
        const products = cart.map(({ imgUrl, ...rest }) => rest);
        const orderData = { ...formData, products, finalSum, discount, initData, isPaying };

        setLoading(true);
        try {
            const responseData = await fetchData(newOrderUrl, 'POST', orderData);
            if (responseData.success) {
                setAccepted(true);
                setCart([]);
                if (isPaying && responseData.paymentData) {
                    navigate('/payment', { state: { paymentData: responseData.paymentData } });
                } else {
                    setError('Сталася помилка при отриманні даних для оплати. ' + responseData.error);
                }
            } else {
                setError(responseData.error || 'Помилка при створенні замовлення. Спробуйте ще раз.');
            }
        } catch (error) {
            setError('Сталася помилка при відправці замовлення. Будь ласка, спробуйте ще раз..' + (error.message || ''));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={style.container}>
            {accepted ? <h2>Замовлення оформлене!</h2> : <h2>ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</h2>}
            {amount === 0 ? (
                !accepted ? (
                    <p>Ваш кошик порожній</p>
                ) : (
                    ''
                )
            ) : (
                <>
                    <h3>ТОВАРИ В КОШИКУ</h3>
                    <CartProducts cart={cart} setCart={setCart} handlerSum={handlerSum} />
                    {/* <form onSubmit={handleSubmit}> */}
                    <form>
                        <Logistics address={formData.address} handleFormChange={handleFormChange} deliveryOption={formData.deliveryOption} />
                        <DateTimePicker formData={formData} handleFormChange={handleFormChange} getZoneUrl={getZoneUrl} />
                        <Payment handleFormChange={handleFormChange} clientSelected={clientSelected} />
                        <textarea
                            id="comment"
                            name="comment"
                            value={formData.comment}
                            onChange={handleFormChange}
                            rows="4"
                            className={style.commentInput}
                            placeholder="Ви можете додати свій коментар до замовлення"
                        />
                        <div className={style.checkboxGroup}>
                            <input
                                type="checkbox"
                                id="noCallback"
                                name="noCallback"
                                className={style.checkboxNoCallback}
                                checked={formData.noCallback}
                                onChange={handleFormChange}
                            />
                            <label htmlFor="noCallback" className={style.labelNoCallback}>
                                Не дзвонити
                            </label>
                        </div>
                        {formData.deliveryOption !== 'pickup' && (
                            <div className={style.checkboxGroupBirthday}>
                                <input type="checkbox" id="birthday" name="birthday" checked={formData.birthday} onChange={handleFormChange} />
                                <label htmlFor="birthday" className={style.labelBirthday}>
                                    <span className={`${style.customCheckbox} ${formData.birthday ? style.customCheckboxChecked : ''}`}>
                                        {formData.birthday && <span className={style.checkmark}>✔</span>}
                                    </span>
                                    У мене день народження
                                </label>
                            </div>
                        )}
                        {error && <p className={style.error}>{error}</p>}
                        {!isOrderPossible && <p className={style.error}>{errorDescription}</p>}

                        {totalSum !== finalSum && (
                            <>
                                <h2>Разом {totalSum} ₴</h2>
                                <h2>Знижка {discount} %</h2>
                            </>
                        )}
                        <h2>До сплати {finalSum} ₴</h2>
                        {
                            clientSelected && isOrderPossible && (
                                // <button type="submit" disabled={loading} className={style.btnSubmit}>
                                <button disabled={loading} className={style.btnSubmit} onClick={handleSubmit}>
                                    {loading ? 'Завантаження...' : 'Оформити замовлення'}
                                </button>
                            )
                            // <div className={style.groupPay}>
                            //     <span>Сплатити зараз:</span>
                            //     <button type="button" onClick={(e) => handleSubmit(e, true)} className={style.btnPay}>
                            //         <img src="logo_liqpay_for_white.svg" alt="LiqPay" className={style.payImage} />
                            //     </button>
                            // </div>
                        }
                    </form>
                </>
            )}
            {accepted && (
                <>
                    <p>Дякуємо за замовлення!❤️ Його деталі Ви завжди можете переглянути в розділі</p>
                    <NavLink to="/orders" className={style.myOrders}>
                        «Мої замовлення ✅»
                    </NavLink>
                </>
            )}
        </div>
    );
}

export default Cart;
