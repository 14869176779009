import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../notFound/NotFound';
import style from './Products.module.css';
import Product from '../product/Product';
import appContext from '../../context/appContext';

function Products() {
    const { products } = useContext(appContext);
    const { slug } = useParams();

    const filteredProducts = useMemo(() => {
        if (slug !== 'promotions') {
            return products.filter((item) => item.categorySlug === slug);
        } else {
            return products.filter((item) => item.discount !== 0);
        }
    }, [slug, products]);

    return filteredProducts.length === 0 ? (
        <NotFound />
    ) : (
        <div className={style.itemsList}>
            {filteredProducts.map((item) => (
                <Product item={item} key={item.id} />
            ))}
        </div>
    );
}

export default Products;
