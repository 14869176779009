import { useCallback, useContext, useEffect, useState } from 'react';
import { IoReloadCircle } from 'react-icons/io5';
import style from './MyOrders.module.css';
import { fetchData } from '../../data/fetchData';
import AppContext from '../../context/appContext';
import { useNavigate } from 'react-router-dom';
import Button from '../../UI/Button';

function MyOrders({ myOrdersUrl, paymentUrl, initData }) {
    const { products, related, setCart, setRepeatOrderParams } = useContext(AppContext);
    const [reload, setReload] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [orders, setOrders] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [activeOrderId, setActiveOrderId] = useState(null);
    const navigate = useNavigate();

    const handleError = (message) => {
        setError(message);
        setLoading(false);
    };

    const getMyOrders = useCallback(async () => {
        setLoading(true);
        setError('');
        try {
            const responseData = await fetchData(myOrdersUrl, 'POST', { initData });
            if (responseData.success) {
                setOrders(responseData.orders);
                setPhoneNumber(responseData?.phoneNumber || '');
            } else {
                handleError(responseData.error || 'Помилка при отриманні замовлень. Спробуйте ще раз.');
            }
        } catch (error) {
            handleError('Сталася помилка при отриманні замовлень. Будь ласка, спробуйте ще раз.');
        } finally {
            setLoading(false);
        }
    }, [initData, myOrdersUrl]);

    useEffect(() => {
        getMyOrders();
    }, [getMyOrders, reload]);

    const buildCartFromOrder = (order) => {
        const productsMap = products.reduce((acc, product) => {
            acc[product.id] = product;
            return acc;
        }, {});

        return order.products.reduce((acc, orderProduct) => {
            const currentProduct = productsMap[orderProduct.id];
            if (currentProduct) {
                const price = currentProduct.discount === 0 ? currentProduct.price : currentProduct.promoPrice;
                const sum = Math.round(price * orderProduct.amount * 100) / 100;
                acc.push({
                    ...currentProduct,
                    noDiscountPrice: currentProduct.price,
                    amount: orderProduct.amount,
                    sum,
                    related: false,
                });
            } else {
                const relatedProduct = [...related.chopsticks, ...related.sauces].find((item) => item.id === orderProduct.id);

                if (relatedProduct) {
                    const sum = Math.round(relatedProduct.price * orderProduct.amount * 100) / 100;
                    acc.push({
                        ...relatedProduct,
                        amount: orderProduct.amount,
                        sum,
                        related: true,
                        discountForbidden: true,
                        noDiscountPrice: relatedProduct.price,
                        discount: 0,
                    });
                }
            }
            return acc;
        }, []);
    };

    const handleRepeat = (orderId) => {
        setActiveOrderId(orderId);
        const order = orders.find((order) => order.id === orderId);
        if (!order) return;

        const newCart = buildCartFromOrder(order);
        setCart(newCart);
        if (newCart && newCart.length > 0) {
            const { deliveryOption, location, address, deliveryTiming, comment } = order;
            setRepeatOrderParams({
                deliveryOption,
                location,
                address,
                deliveryTiming,
                comment,
            });
            navigate('/cart');
        }
        setActiveOrderId(null);
    };

    const handlePay = async (orderId) => {
        setActiveOrderId(orderId);
        const orderData = { initData, orderId, isForPayment: true };
        setLoading(true);
        try {
            const responseData = await fetchData(paymentUrl, 'POST', orderData);
            if (responseData.success && responseData.paymentData) {
                navigate('/payment', { state: { paymentData: responseData.paymentData } });
            } else {
                setError(responseData.error || 'Помилка при отриманні даних оплати. Спробуйте ще раз.');
            }
        } catch (error) {
            setError('Сталася помилка при відправці замовлення. Будь ласка, спробуйте ще раз. ' + (error.message || ''));
        } finally {
            setActiveOrderId(null);
            setLoading(false);
        }
    };

    const handleButtonReload = () => {
        setReload(!reload);
        setIsDisabled(true);
        setTimeout(() => setIsDisabled(false), 30000);
    };

    const OrderList = () => (
        <ul className={style.ordersList}>
            {orders.map((order) => (
                <li key={order.id} className={style.orderItem}>
                    <div className={style.orderHeader}>
                        <div className={style.orderDate}>
                            <strong>Дата:</strong> {order.date}
                        </div>
                        <div className={style.deliveryLocation}>
                            <span className={style.location}>🏡 {order.deliveryOption === 'pickup' ? order.location : order.addressString}</span>
                            {/* {order.deliveryOption === 'pickup' ? (
                            ) : (
                                <span>🏡 {order.addressString}</span>
                            )} */}
                        </div>
                        <div className={style.deliveryTiming}>
                            <span>⏰ {order.deliveryTiming}</span>
                        </div>
                        <div className={style.deliveryTiming}>
                            <span>✍️ {order.comment}</span>
                        </div>
                        <div className={style.orderActions}>
                            {order.isPayable && (
                                <button
                                    className={style.repeatButton}
                                    onClick={() => handlePay(order.id)}
                                    disabled={loading && activeOrderId === order.id}
                                >
                                    Оплатити
                                </button>
                            )}
                            <button
                                className={style.repeatButton}
                                onClick={() => handleRepeat(order.id)}
                                disabled={loading && activeOrderId === order.id}
                            >
                                Повторити
                            </button>
                        </div>
                    </div>

                    <div className={style.orderDetails}>
                        {order.products.map((product, index) => (
                            <li key={product.id} className={style.product}>
                                <span className={style.productNumber}>{index + 1}.</span>
                                <span className={style.productTitle}>{product.title}</span>
                                <span className={style.productAmount}>{product.amount}</span>
                                <span className={style.productSum}>{product.price}₴</span>
                                <span className={style.productSum}>{product.sum}₴</span>
                            </li>
                        ))}
                        <div className={style.totalSum}>
                            <strong>Сума замовлення:</strong> {order.sum}₴
                        </div>
                    </div>
                    <hr className={style.separator} />
                </li>
            ))}
        </ul>
    );

    const renderContent = () => {
        if (loading && !orders.length) {
            return <p className={style.loading}>Завантаження...</p>;
        }

        if (error) {
            return (
                <>
                    <p className={style.error}>{error}</p>
                    <button
                        className={style.repeatButton}
                        onClick={() => {
                            setError('');
                            setReload(!reload);
                        }}
                    >
                        Оновити
                    </button>
                </>
            );
        }

        if (orders.length === 0) {
            return (
                <>
                    <p className={style.noOrders}>У вас немає замовлень.</p>
                    <button className={style.repeatButton} onClick={handleButtonReload} disabled={isDisabled}>
                        Оновити
                    </button>
                </>
            );
        }

        return (
            <div className={style.container}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button btnclass="headerButton" onClick={handleButtonReload} disabled={isDisabled}>
                        <IoReloadCircle className={style.iconReload} />
                    </Button>
                    <h3 className={style.ordersTitle}>Мої замовлення</h3>
                </div>
                <OrderList />
            </div>
        );
    };

    return (
        <>
            <h3 className={style.ordersTitle}>Мій номер: {phoneNumber}</h3>
            {renderContent()};
        </>
    );
}

export default MyOrders;
